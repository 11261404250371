$.extend(frappe._messages, {
    "My Account" : "Môj účet",
    "Message" : "Oznam",
    "Invalid Login" : "Neplatné prihlásenie",
    "Not permitted" : "Nepovolené",
    "Verifying..." : "Overujem...",
    "Success": "Úspešné",
    "Password Updated": "Heslo obnovené",
    "Invalid Login. Try again.": "Neplatné prihlásenie",
    "Save comment": "Uložiť poznámku",
    "Comment saved": "Poznámka uložená",
    "Saved": "Uložené",
    "The following mandatory fields must be filled:<br>": "Tieto povinné polia nie sú vyplnené:<br>",
    "Hint: Include symbols, numbers and capital letters in the password" : "Použite aj $peciálne znaky, č1sla a VEĽKÉ písmená.",
    "Invalid Password": "Slabé heslo",
    "Success! You are good to go 👍": "Super! Teraz je všetk OK👍 ",
    "Please enter the password": "Prosím vložte nové heslo"
});
